var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"delivery_step2"},[_c('div',{staticClass:"home3-3__wr"},[_c('p',{staticClass:"ti a"},[_vm._v(" 맞춤식단 서비스를 준비중입니다")]),_c('p',{staticClass:"ti color2"},[_vm._v("주문 정보")]),_vm._m(0),_c('p',{staticClass:"ti"},[_vm._v("식단 리스트")]),_c('div',{staticClass:"home3-3__top__b"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"home4-1-2__head"},[_c('el-steps',{attrs:{"active":0,"align-center":""}},[_c('el-step',{attrs:{"title":"주문접수"}}),_c('el-step',{attrs:{"title":"매장배달중"}}),_c('el-step',{attrs:{"title":"픽업대기"}}),_c('el-step',{attrs:{"title":"픽업완료"}})],1)],1),_c('router-link',{attrs:{"to":{ name: 'cancelStep1' }}},[_c('p',{staticClass:"cancel_box"},[_vm._v("부분취소")])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"home4-1-2__head"},[_c('el-steps',{attrs:{"active":0,"align-center":""}},[_c('el-step',{attrs:{"title":"주문접수"}}),_c('el-step',{attrs:{"title":"매장배달중"}}),_c('el-step',{attrs:{"title":"픽업대기"}}),_c('el-step',{attrs:{"title":"픽업완료"}})],1)],1),_c('router-link',{attrs:{"to":{ name: 'cancelStep1' }}},[_c('p',{staticClass:"cancel_box"},[_vm._v("부분취소")])]),_vm._m(5)],1),_c('div',{staticClass:"home3-3__top__c"},[_c('table',[_c('caption',[_vm._v("선택된 픽업 장소")]),_c('tbody',[_vm._m(6),_vm._m(7),_c('tr',[_c('th',[_vm._v("픽업 시간")]),_c('td',[_c('el-select',{attrs:{"placeholder":"가능 시간 대","popper-append-to-body":false},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},_vm._l((_vm.times),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)])])])]),_c('div',{staticClass:"home4-1-2__tail"},[_c('router-link',{attrs:{"to":{ name: 'cancelStep2' }}},[_vm._v("주문취소")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home3-3__top__a"},[_c('div',{staticClass:"home3-3__top__a-1"},[_c('p',{staticClass:"tti"},[_vm._v("식단형태")]),_c('p',{staticClass:"ttti"},[_vm._v(": 샐러드")]),_c('p',{staticClass:"tti"},[_vm._v("식단목적")]),_c('p',{staticClass:"ttti"},[_vm._v(": 체중감량")]),_c('p',{staticClass:"tti"},[_vm._v("특이사항")]),_c('p',{staticClass:"ttti"},[_vm._v(": 없음")])]),_c('div',{staticClass:"home3-3__top__a-2"},[_c('p',{staticClass:"tti"},[_vm._v("주당 식사 수")]),_c('p',{staticClass:"ttti"},[_vm._v(": 5")]),_c('p',{staticClass:"tti"},[_vm._v("픽업요일")]),_c('p',{staticClass:"ttti"},[_vm._v(": 수요일")]),_c('p',{staticClass:"tti"},[_vm._v("식단관리 기간")]),_c('p',{staticClass:"ttti"},[_vm._v(": 2주")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"tti"},[_vm._v("[ 1주차 ]"),_c('span',{staticClass:"tti color2"},[_vm._v(" 2022년 01월 01일 ~ 01월 07일")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home3-3__box"},[_c('p',{staticClass:"ttti"},[_vm._v("쉬림프 샐러드 X1개")]),_c('p',{staticClass:"ttti a"},[_vm._v("7,000원")]),_c('p',{staticClass:"ttti"},[_vm._v("닭목살 샐러드 X2개")]),_c('p',{staticClass:"ttti a"},[_vm._v("14,000원")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"tti"},[_vm._v("[ 2주차 ]"),_c('span',{staticClass:"tti color2"},[_vm._v(" 2022년 01월 01일 ~ 01월 07일")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home3-3__box"},[_c('p',{staticClass:"ttti"},[_vm._v("쉬림프 샐러드 X1개")]),_c('p',{staticClass:"ttti a"},[_vm._v("7,000원")]),_c('p',{staticClass:"ttti"},[_vm._v("닭목살 샐러드 X2개")]),_c('p',{staticClass:"ttti a"},[_vm._v("14,000원")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home3-3__top__b-1"},[_c('p',{staticClass:"tii"},[_vm._v("총결제 금액")]),_c('p',{staticClass:"tiii"},[_vm._v("14,000원")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("장소 이름")]),_c('td',[_vm._v("이름")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("장소 주소")]),_c('td',[_vm._v("주소")])])
}]

export { render, staticRenderFns }